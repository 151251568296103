import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Card, Container, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import LoadingScreen from './LoadingScreen';
import { useCountdownStyles } from './styles';

function Countdown(props) {
  const { date } = props;
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [second, setSecond] = useState();
  const [end, setEnd] = useState();
  const [loading, setLoading] = useState(true);
  const classes = useCountdownStyles();

  const finalDate = new Date(date).getTime();

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = finalDate - now;
      setDay(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHour(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      setMinute(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSecond(Math.floor((distance % (1000 * 60)) / 1000));

      if (distance < 0) {
        clearInterval(interval);
        setEnd('Faça seu cadastro!');
      }
      setLoading(false);
    }, 1000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [finalDate]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (end)
    return (
      <Container maxWidth={false} disableGutters>
        <Box mb={3}>
          <Card>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              p={2}
              bgcolor='#F2EFEF'
            >
              <Typography variant='h5'>Baixe agora a NAV versão Mobile! </Typography>
            </Box>
            <Box py={3} px={6} display='flex' justifyContent='center'>
              <Button
                variant='contained'
                color='primary'
                href='https://play.google.com/store/apps/details?id=com.nav_mobile'
              >
                Baixe aqui
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    );

  return (
    <Container maxWidth={false} disableGutters>
      <Box mb={3}>
        <Card>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            p={2}
            bgcolor='#F2EFEF'
          >
            <Typography variant='h5'>VEM NOVIDADE POR AÍ...</Typography>
          </Box>
          <Box py={3} px={6}>
            {end && <Typography>Acabou!</Typography>}
            <Grid container spacing={2} justify='space-between' alignItems='center'>
              <Grid item>
                <Typography align='center' variant='h1' className={classes.number}>
                  {end ? '0' : day}
                </Typography>
                <Typography align='center' variant='subtitle1'>
                  DIAS
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant='h1' className={classes.separator}>
                  :
                </Typography>
              </Grid>

              <Grid item>
                <Typography align='center' variant='h1' className={classes.number}>
                  {end ? '0' : hour}
                </Typography>
                <Typography align='center' variant='subtitle1'>
                  HORAS
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant='h1' className={classes.separator}>
                  :
                </Typography>
              </Grid>

              <Grid item>
                <Typography align='center' variant='h1' className={classes.number}>
                  {end ? '0' : minute}
                </Typography>
                <Typography align='center' variant='subtitle1'>
                  MINUTOS
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant='h1' className={classes.separator}>
                  :
                </Typography>
              </Grid>

              <Grid item>
                <Typography align='center' variant='h1' className={classes.number}>
                  {end ? '0' : second}
                </Typography>
                <Typography align='center' variant='subtitle1'>
                  SEGUNDOS
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Container>
  );
}

Countdown.propTypes = {
  date: PropTypes.string.isRequired,
};

export default Countdown;
