import React from 'react';
import { useSelector } from 'react-redux';
import { SwipeableDrawer, Hidden, Box, Paper } from '@material-ui/core';
import ProfileBar from 'pages/Home/ProfileBar';

function MobileNav(props) {
  const { mobile, mobileOpen, mobileClose } = props;
  const { user } = useSelector((state) => state.account);
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <Hidden lgUp>
      <SwipeableDrawer
        anchor='right'
        open={mobile}
        onOpen={mobileOpen}
        onClose={mobileClose}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box component={Paper}>
          <ProfileBar user={user} />
        </Box>
      </SwipeableDrawer>
    </Hidden>
  );
}

export default MobileNav;
