import React, { useState, useEffect, useRef } from 'react';
import 'video.js/dist/video-js.css';
import './skin.scss';
import videojs from 'video.js';
import 'videojs-youtube';
import 'videojs-contrib-quality-levels';
import 'videojs-http-source-selector';
import PropTypes from 'prop-types';

const defaultOptions = {
  fill: true,
  playbackRates: [0.5, 1, 1.5, 2],
  controls: true,
  language: 'pt-BR',
  preload: 'auto',
  fluid: true,
  html5: {
    hls: {
      enableLowInitialPlaylist: true,
      smoothQualityChange: true,
      overrideNative: true,
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    useBandwidthFromLocalStorage: true,
  },
  youtube: {
    iv_load_policy: 1,
  },
};

function VideoPlayer(props) {
  const { src, options, type, onPlay, onPause, onSeeked, onEnd, onRateChange } = props;
  const [player, setPlayer] = useState(null);
  const playerRef = useRef();

  useEffect(() => {
    const videoPlayer = videojs(playerRef.current, { ...defaultOptions, ...options });
    setPlayer(videoPlayer);

    return () => {
      videoPlayer.dispose();
    };
  }, [options]);

  useEffect(() => {
    if (player !== null) {
      player.src({ src, type });

      player.on('play', () => {
        onPlay(player.currentTime());
      });

      player.on('pause', () => {
        onPause(player.currentTime());
      });

      player.on('seeked', () => {
        onSeeked(player.currentTime());
      });

      player.on('ended', () => {
        onEnd();
      });

      player.on('ratechange', () => {
        onRateChange(player.playbackRate());
      });
    }
  }, [src, player, type, onEnd, onPause, onPlay, onRateChange, onSeeked]);

  return (
    <div>
      <div data-vjs-player>
        <video ref={playerRef} className='video-js vjs-16-9 vjs-lime'></video>
      </div>
    </div>
  );
}

VideoPlayer.propTypes = {
  type: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  options: PropTypes.object,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onSeeked: PropTypes.func,
  onEnd: PropTypes.func,
  onRateChange: PropTypes.func,
};

export default VideoPlayer;
