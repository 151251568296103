import { makeStyles } from '@material-ui/core';

const duration = (value) => ( ((value % 5) - 1) / 5 ) * (2.3 - 0.8) + 0.8

export const useSplashStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
  logo: {
    width: 200,
    maxWidth: '100%',
  },
}));

export const useLoadingStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
}));

export const useHeaderStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const useDefaultPageStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

export const useTagsStyle = makeStyles((theme) => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2),
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, .15)',
    color: '#fff',
    border: '1px solid',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

export const useCardStyles = makeStyles((theme) => ({
  container: {
    animationDuration: props => `${duration(props.animDuration)}s`,
    animationName: '$slide',
    animationTimingFunction: 'cubic-bezier(0.250, 0.460, 0.450, 0.940) both'
  },

  place: {
    backgroundColor: 'red',
    color: 'white',
    height: 30,
    width: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },

  "@keyframes swing": {
    from: {
      transform: 'rotateX(-100deg)',
      transformOrigin: 'top',
      opacity: 0
    },
    to: {
      transform: 'rotateX(0deg)',
      transformOrigin: 'top',
      opacity: 1
    }
  },

  "@keyframes slide": {
    from: {
      transform: 'translateX(150px)',
      opacity: 0
    },
    to: {
      transform: 'translateX(0)',
      opacity: 1
    }
  },

  liveButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  liveButtonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const useCountdownStyles = makeStyles((theme) => ({
  number: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '5rem',
    },
  },
  separator: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '3rem',
    },
  },
}));
