import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Box,
  Container,
  IconButton,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  Link,
  SvgIcon,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  MoreHoriz,
  Instagram,
  Facebook,
  LinkedIn,
  Assignment,
  Chat,
  Work,
} from '@material-ui/icons';
import { IoFlaskSharp as IoFlask } from 'react-icons/io5';
import { logout } from 'store/accountSlice';
import { Label, AlertBadge, LibraryIcon } from 'components';
import { capitalizeSentence } from 'utils/helper';
import { useLibrary } from 'utils/hooks';
import { useProfileStyles } from './styles';

function ProfileBar(props) {
  const { user } = props;
  const dispatch = useDispatch();
  const { loading, goToLibrary } = useLibrary();
  const alerts = useSelector((state) => state.alerts);
  const classes = useProfileStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Container maxWidth={false} disableGutters>
      <Card>
        <Box bgcolor="#F2EFEF" pb={3}>
          <Box display="flex" justifyContent="flex-end" mt={0.5}>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreHoriz />
            </IconButton>
          </Box>
          <Box px={3} mt={-3} display="flex">
            <Avatar alt="User" className={classes.avatar} src={user.photo} />
            <Box p={3}>
              <Typography variant="h4" color="textSecondary">
                {user.firstName} {user.lastName}
              </Typography>
              <Typography variant="overline" color="textSecondary">
                {user.userable && capitalizeSentence(user.userable.course.name)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={2} p={3}>
          <Box mb={1}>
            <Typography variant="overline" color="secondary" component="h4">
              Principal
            </Typography>
          </Box>
          <Box mb={2} display="flex">
            <AlertBadge value={alerts.all.length - alerts.read.length} />
            <Box mx={1}>
              <Link
                variant="subtitle1"
                color="secondary"
                to="/comunicados"
                component={RouterLink}
              >
                Comunicados
              </Link>
            </Box>
            <Label color="new">Novo</Label>
          </Box>
          <Box mb={2} display="flex">
            <SvgIcon color="secondary">
              <Assignment />
            </SvgIcon>
            <Box ml={1}>
              <Link
                variant="subtitle1"
                color="secondary"
                to="/atividades"
                component={RouterLink}
              >
                Minhas Atividades
              </Link>
            </Box>
          </Box>
          <Box mb={2} display="flex">
            <SvgIcon color="secondary">
              <IoFlask />
            </SvgIcon>
            <Box mx={1}>
              <Link
                variant="subtitle1"
                color="secondary"
                to="/laboratorios"
                component={RouterLink}
              >
                Laboratórios Virtuais
              </Link>
            </Box>
          </Box>
          <Box mb={2} display="flex">
            <SvgIcon color="secondary">
              <Work />
            </SvgIcon>
            <Box mx={1}>
              <Link
                variant="subtitle1"
                color="secondary"
                to="/servicos academicos"
                component={RouterLink}
              >
                Serviços Acadêmicos
              </Link>
            </Box>
          </Box>
          <Box mb={4} display="flex">
            {loading ? (
              <CircularProgress color="secondary" size="1rem" />
            ) : (
              <LibraryIcon />
            )}
            <Box mx={1}>
              <Link
                style={{ cursor: 'pointer' }}
                variant="subtitle1"
                color="secondary"
                onClick={goToLibrary}
              >
                Minha Biblioteca
              </Link>
            </Box>
            <Label color="new">Novo</Label>
          </Box>
          <Typography variant="overline" component="h4" color="secondary">
            Disciplinas
          </Typography>
          {user.userable &&
            user.userable.classroom_students.map(
              ({ classroom_subject, id }) => (
                <Box key={id} my={1}>
                  <Link
                    to={`/${classroom_subject.id}`}
                    variant="body1"
                    component={RouterLink}
                  >
                    {classroom_subject.id} -{' '}
                    {capitalizeSentence(classroom_subject.subject.name)}
                  </Link>
                </Box>
              )
            )}
          <Box mt={3}>
            <Typography variant="overline" component="h4" color="secondary">
              Redes Sociais
            </Typography>
          </Box>
          <Box mt={2} display="flex">
            {user.instagram && (
              <Link
                href={`https://instagram.com/${user.instagram}`}
                target="_blank"
                variant="body1"
              >
                <Instagram />
              </Link>
            )}
            {user.facebook && (
              <Box ml={2}>
                <Link href={user.facebook} target="_blank" variant="body1">
                  <Facebook />
                </Link>
              </Box>
            )}
            {user.linkedin && (
              <Box ml={2}>
                <Link href={user.linkedin} target="_blank" variant="body1">
                  <LinkedIn />
                </Link>
              </Box>
            )}
          </Box>
          <Box mt={3}>
            <Typography variant="overline" component="h4" color="secondary">
              Suporte
            </Typography>
            <Box mt={2}>
              <Button
                href="https://api.whatsapp.com/send?phone=553196268077&text=Ol%C3%A1%21"
                target="_blank"
                variant="outlined"
                color="primary"
                startIcon={<Chat />}
              >
                Fale com o Tony!
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem component={RouterLink} to="/perfil">
          Editar Perfil
        </MenuItem>
        <MenuItem onClick={() => dispatch(logout())}>Sair</MenuItem>
      </Menu>
    </Container>
  );
}

export default ProfileBar;
