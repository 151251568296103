import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Icon } from '@material-ui/core';
import { Link, Description, VolumeUp, Videocam, Assignment } from '@material-ui/icons';
import sagahSVG from 'utils/assets/sagah-icon.svg';
import flaskSVG from 'utils/assets/flask.svg';

const icons = {
  video: { type: 'Vídeo', icon: <Videocam color='primary' /> },
  audio: { type: 'Podcast', icon: <VolumeUp color='primary' /> },
  reading: { type: 'Arquivo', icon: <Description color='primary' /> },
  link: { type: 'Link', icon: <Link color='primary' /> },
  exercise: { type: 'Atividade', icon: <Assignment color='primary' /> },
  sagah: {type: 'Sagah', icon: <Icon><img style={{width: '24px'}} src={sagahSVG} alt="Ícone Sagah"/></Icon>},
  labs: {type: 'Labs', icon: <Icon><img style={{width: '18px', margin: '0 6px 0 0'}} src={flaskSVG} alt="Ícone Labs"/></Icon>}
};

function ActivityIcon(props) {
  const { type, value, label, selected } = props;
  return (
    <Box display='flex'>
      <Box>{icons[type].icon}</Box>
      <Box ml={0.5}>
        {label ? (
          <Typography variant={selected ? 'h5' : 'subtitle2'} color='textSecondary'>
            {label}
          </Typography>
        ) : (
          <Typography variant='caption' color='primary'>
            {value} {`${icons[type].type}${value !== 1 ? 's' : ''}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

ActivityIcon.propTypes = {
  type: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
};

ActivityIcon.defaultProps = {
  type: 'reading',
  value: 0,
};

export default ActivityIcon;
