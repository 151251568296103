import React from 'react';
import { TextField } from '@material-ui/core';
import InputMask from "react-input-mask";

export default function MaskedInput(props){
    const {
        error, 
        fullWidth, 
        helperText, 
        label,
        name,
        onBlur,
        onChange,
        value,
        variant,
        mask
    } = props;

    return(
        <InputMask
        error={error}
        fullWidth={fullWidth}
        helperText={helperText}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        variant={variant}
        value={value}
        mask={mask}
        >
            <TextField/>
        </InputMask>

    )
}