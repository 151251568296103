import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from 'utils/api';

const initialState = {
  all: [],
  read: []
};

export const fetchAlerts = createAsyncThunk('alerts/fetch', async () => {
  const { data } = await axios.get('alerts');
  let readAlerts = JSON.parse(localStorage.getItem('readAlerts'));
  return {all: data.data, read: readAlerts ? readAlerts : [] };
});


const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    markAsRead(state, action) {
      const storedAlerts = JSON.parse(localStorage.getItem('readAlerts'));
      let newAlerts = []
      
      if (storedAlerts) {
        newAlerts = storedAlerts
      }
      
      if (!newAlerts.includes(action.payload)) {
        newAlerts.push(action.payload)
      }

      localStorage.setItem('readAlerts', JSON.stringify(newAlerts));
      state.read = newAlerts
    }
  },
  extraReducers: {
    [fetchAlerts.pending]: (state) => {
      state.loading = true;
    },
    [fetchAlerts.rejected]: (state, action) => {
      state.loading = false;
      console.log(action);
    },
    [fetchAlerts.fulfilled]: (state, action) => {
      state.loading = false;
      state.all = action.payload.all
      state.read = action.payload.read
    },
  },
});

export const { markAsRead } = alertSlice.actions;

export default alertSlice.reducer;