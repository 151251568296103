import { makeStyles } from '@material-ui/core';

export const useAccountStyles = makeStyles((theme) => ({
  avatar: {
    height: 64,
    width: 64,
    marginLeft: theme.spacing(2),
    border: '2px solid white',
  },
  popover: {
    width: 200,
  },
  chip: {
    padding: theme.spacing(1),
    fontWeight: 500,
    borderRadius: theme.shape.borderRadius * 2.5,
  },
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  toolbar: {
    minHeight: 100,
  },
}));
