import { configureStore } from '@reduxjs/toolkit';

import account from './accountSlice';
import lesson from './lessonSlice';
import alerts from './alertsSlice';

const store = configureStore({
  reducer: {
    account,
    lesson,
    alerts
  },
});

export default store;
