import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Typography, Box, makeStyles } from '@material-ui/core';
import CountUp from 'react-countup';

function CircularProgressWithLabel(props) {
  const { value, small, ...rest } = props;
  const [progress, setProgress] = useState(0);
  const useStyles = makeStyles(({ palette }) => ({
    static: { color: '#F2EFEF', position: 'absolute', left: 0 },
    color: {
      color:
        value > 50
          ? palette.secondary.main
          : value > 30
          ? palette.warning.main
          : palette.error.main,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(value);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [value]);

  return (
    <Box position='relative'>
      <CircularProgress
        thickness={small ? 3 : 5}
        size={small ? 50 : 130}
        variant='indeterminate'
        value={100}
        className={classes.static}
        {...rest}
      />
      <CircularProgress
        thickness={small ? 3 : 5}
        size={small ? 50 : 130}
        variant='static'
        value={progress}
        className={classes.color}
        {...rest}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
        style={{ width: small ? 50 : 130, height: small ? 50 : 130 }}
      >
        <Typography
          className={classes.color}
          variant={small ? 'h5' : 'h3'}
          component='span'
        >
          <CountUp end={value} suffix='%' />
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
