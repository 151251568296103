const warn = (...args) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  console.warn(...args);
};

class GTM {
  #id = null;
  #init = false;

  configure(containerId) {
    this.#id = containerId;
  }

  initialize(containerId) {
    if (this.#init) {
      warn('Container já foi inicializado');
      return;
    }

    if (!document) {
      warn('GTM só funciona no cliente');
      return;
    }

    if (!containerId) {
      warn('Nenhum ID de container encontrado');
      return;
    }

    this.configure(containerId);

    const script = document.createElement('script');
    const noScript = document.createElement('noscript');

    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${this.#id}');`;

    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${
      this.#id
    }"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noScript, document.body.childNodes[0]);
  }

  push(...args) {
    if (!window) {
      warn('GTM só funciona no cliente');
      return;
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(...args);
  }
}

const gtm = new GTM();

export default gtm;
