import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Card as MuiCard,
  Container,
  Button,
  Link,
  Tooltip,
} from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import { parseISO } from 'date-fns';
import PlaceIcon from '@material-ui/icons/Place';

import info from 'utils/assets/info.svg';

import { CircularProgress, ActivityIcon } from 'components';
import { useCardStyles } from './styles';

function Card(props) {
  const {
    id,
    title,
    date,
    description,
    progress,
    subjectId,
    audios,
    files,
    links,
    lives,
    videos,
    start,
    end,
    index,
    place,
  } = props;
  const classes = useCardStyles({ animDuration: index });

  const liveButton = (lives) => {
    const now = new Date().getTime();
    const begin = parseISO(start).getTime();
    const finish = parseISO(end).getTime();

    if (lives && now >= begin && now <= finish) {
      return (
        <Button
          component={RouterLink}
          to={`/${subjectId}/${id}`}
          variant='outlined'
          className={classes.liveButton}
        >
          <PlayArrow fontSize='small' className={classes.liveButtonIcon} />
          Aula ao Vivo acontecendo
        </Button>
      );
    } else {
      return (
        <Typography variant='body1'>
          <b>{parseISO(date).toLocaleDateString()}</b>{' '}
          {parseISO(start).toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
          })}{' '}
          às{' '}
          {parseISO(end).toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Typography>
      );
    }
  };

  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      <Box mb={3}>
        <MuiCard>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p={2}
            bgcolor='#F2EFEF'
          >
            <Typography variant='h3' color='textSecondary'>
              {title}{' '}
              <Link component={RouterLink} to={`/${subjectId}`}>
                <Tooltip title='Ver detalhes da disciplina'>
                  <img src={info} alt='' />
                </Tooltip>
              </Link>
            </Typography>
            {liveButton(lives)}
          </Box>
          <Box p={2}>
            <Grid container spacing={2} justify='center' alignItems='center'>
              <Grid item md={3} sm={12}>
                <CircularProgress
                  value={
                    (progress > 100 ? 100 :
                      progress < 0 ? 0 :
                        parseInt(progress)) || 0}
                  variant='static'
                />
              </Grid>
              <Grid item md={9} sm={12}>
                <Box mb={2}>
                  <Typography variant='subtitle1' color='textSecondary'>
                    {description ? (
                      description
                    ) : (
                      <Typography variant='overline' color='textPrimary'>
                        Esta aula não tem uma descrição
                      </Typography>
                    )}
                  </Typography>
                  <Typography>
                    {place && (
                        <Typography color='textPrimary' className={classes.place}>
                        <PlaceIcon fontSize="small" /> Local: {place}
                      </Typography>
                      )}
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={7}>
                    <Box display='flex' flexDirection='row' justifyContent='space-around'>
                      {audios > 0 && <ActivityIcon type='audio' value={audios} />}
                      {videos > 0 && <ActivityIcon type='video' value={videos} />}
                      {files > 0 && <ActivityIcon type='reading' value={files} />}
                      {links > 0 && <ActivityIcon type='link' value={links} />}
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <Button
                      component={RouterLink}
                      to={`/${subjectId}/${id}`}
                      size='small'
                      variant='contained'
                      color='primary'
                    >
                      Ver conteúdo da aula
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </MuiCard>
      </Box>
    </Container>
  );
}

Card.propTypes = {
  live: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.node,
  content: PropTypes.array,
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Card;
