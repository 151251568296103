import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

import sad from 'utils/assets/emoji-sad.svg';
import happy from 'utils/assets/emoji-happy.svg';
import love from 'utils/assets/emoji-love.svg';
import neutral from 'utils/assets/emoji-neutral.svg';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: 'none',
  },
}))(Tooltip);

function ValueLabel(props) {
  const { children, open, value } = props;

  const setIcon = (value) => {
    if (value >= 9) return love;
    if (value >= 6) return happy;
    if (value >= 4) return neutral;
    return sad;
  };

  return (
    <CustomTooltip
      open={open}
      enterTouchDelay={0}
      placement='top'
      title={<img width={25} src={setIcon(value)} alt='Icon' />}
    >
      {children}
    </CustomTooltip>
  );
}

export default ValueLabel;
