import React, { useState } from 'react';
import TopBar, { MobileNav } from '../TopBar';
import { useStyles } from './styles';

function Dashboard(props) {
  const { children } = props;
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <MobileNav
        mobile={isMobileNavOpen}
        mobileOpen={() => setMobileNavOpen(true)}
        mobileClose={() => setMobileNavOpen(false)}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div id='dashboard' className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
