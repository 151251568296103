import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  details: null,
  selected: null,
  selectedId: null,
};

const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    setLesson(state, action) {
      state.details = action.payload;
    },
    setSelected(state, action) {
      const { id, sectionId } = action.payload;
      state.selectedId = id;
      state.selected = state.details
        .find((item) => item.section.id === sectionId)
        .items.find((item) => item.id === id);
    },
    resetSelected(state) {
      state.selected = null;
      state.selectedId = null;
    },
  },
});

export const { setLesson, setSelected, resetSelected } = lessonSlice.actions;

export default lessonSlice.reducer;
