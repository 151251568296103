import React, { useState, useEffect, useRef } from 'react';
import 'video.js/dist/video-js.css';
import './skin.scss';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-http-source-selector';
import bg from 'utils/assets/audiobg.png';

import PropTypes from 'prop-types';

const defaultOptions = {
  playbackRates: [0.5, 1, 1.5, 2],
  controls: true,
  language: 'pt-BR',
  preload: 'auto',
  html5: {
    hls: {
      enableLowInitialPlaylist: true,
      smoothQualityChange: true,
      overrideNative: true,
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    useBandwidthFromLocalStorage: true,
  },
};

function AudioPlayer(props) {
  const { src, options, onPlay, onPause, onSeeked, onEnd, onRateChange } = props;
  const [player, setPlayer] = useState(null);
  const playerRef = useRef();

  useEffect(() => {
    const audioPlayer = videojs(playerRef.current, { ...defaultOptions, ...options });
    setPlayer(audioPlayer);

    return () => {
      audioPlayer.dispose();
    };
  }, [options]);

  useEffect(() => {
    if (player !== null) {
      player.src(src);
      player.aspectRatio('5:1');
      player.poster(bg);

      player.on('play', () => {
        onPlay(player.currentTime());
      });

      player.on('pause', () => {
        onPause(player.currentTime());
      });

      player.on('seeked', () => {
        onSeeked(player.currentTime());
      });

      player.on('ended', () => {
        onEnd();
      });

      player.on('ratechange', () => {
        onRateChange(player.playbackRate());
      });
    }
  }, [src, player, onEnd, onPause, onPlay, onRateChange, onSeeked]);

  return (
    <div>
      <div data-vjs-player>
        <audio ref={playerRef} className='video-js vjs-lime'></audio>
      </div>
    </div>
  );
}

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  options: PropTypes.object,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onSeeked: PropTypes.func,
  onEnd: PropTypes.func,
  onRateChange: PropTypes.func,
};

export default AudioPlayer;
