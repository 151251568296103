import React from 'react';

import logo from 'utils/assets/logo.svg';
import logoColor from 'utils/assets/logo-color.svg';

function Logo(props) {
  const { colored, ...rest } = props;
  return <img src={colored ? logoColor : logo} alt='Logo' {...rest} />;
}

export default Logo;
