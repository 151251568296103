import { createStyles, makeStyles } from '@material-ui/core';

import bg from 'utils/assets/bg.jpg';

export const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        background: `url('${bg}') no-repeat center center fixed`,
        backgroundSize: 'cover',
        '-webkit-background-size': 'cover',
        '-o-background-size': 'cover',
        '-moz-background-size': 'cover',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
);
