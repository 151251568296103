import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import { Auth } from 'components';
import { useStyles } from './styles';
import { createTheme } from 'utils/theme';
import { gtm } from 'utils/lib';
import { THEMES } from 'utils/constants';
import Routes from 'Routes';

const history = createBrowserHistory();

// const containerId = process.env.REACT_APP_ENV === 'prod' ? 'GTM-P6VSKBM' : null;

function App() {
  useStyles();

  useEffect(() => {
    gtm.initialize('GTM-P6VSKBM');
  }, []);

  return (
    <ThemeProvider
      theme={createTheme({ responsiveFontSize: true, theme: THEMES.ROCKET })}
    >
      <SnackbarProvider maxSnack={1}>
        <Router history={history}>
          <Auth>
            <Routes />
          </Auth>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
