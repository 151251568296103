import React from 'react';

import { SvgIcon } from '@material-ui/core';
import icon from 'utils/assets/icon-library.svg';

function LibraryIcon(props) {
    const { color } = props;

    console.log(icon)
    return (
        <SvgIcon
            id="Camada_1"
            data-name="Camada 1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 1628.06 1234.44"
            style={{
                fill: color || "#00cc96"
            }}
        >
            <path class="cls-1" d="M5,248.82c20.88-41.89,39.47-50.18,100.06-43.29V225.1q0,346.79-.1,693.55c0,38,8.24,72.92,32.77,103,28.62,35,65.61,52.36,110.59,55.75,53.45,4,107.31,6.81,160,15.83,91.28,15.64,176.11,48.87,249.61,107.16,7.42,5.88,14.48,12.21,21.64,18.41a70.7,70.7,0,0,1,5.14,5.75c-32.38-5.61-62.94-11.81-93.77-16.07-91.08-12.58-182.73-15.84-274-5.58-78.79,8.86-157.07,22.47-235.43,34.91-37.68,6-57.93-4.7-76.48-43.86Z" />
            <path class="cls-1" d="M768.64,1176.23c-24-20.08-45.7-40.07-69.24-57.53-87.21-64.68-186.11-101.59-292.8-117.14-48.8-7.11-98.3-10-147.58-13.13-41.45-2.67-64.26-24.57-64.27-65.81q-.1-426.55,0-853.1c0-44.5,25-67,69.73-65.65,80.9,2.39,160.35,12.56,237.26,38.94C606.94,78.89,694.33,138.89,755.2,233.5c7.83,12.17,13.52,28.25,13.56,42.53q1.15,446.29.55,892.6C769.31,1170.64,769,1172.65,768.64,1176.23Z" />
            <path class="cls-1" d="M858.7,1175.88v-20.16c0-283.84.83-567.69-.89-851.52-.25-42.47,14.16-74.75,39.49-105.91C977.13,100.1,1082.7,46,1204.23,22.19c54.44-10.66,110.49-13.84,166-18.1,38.42-2.95,63,23.61,63.07,64.14q.17,236.19,0,472.37,0,188.79,0,377.58c0,47.31-21.57,69.17-69.33,70.07-115.28,2.16-227.06,21.09-332.62,69.62-57.84,26.59-110.27,61.24-156.14,105.58-3.78,3.66-7.58,7.31-11.47,10.85C863.1,1174.9,861.9,1174.92,858.7,1175.88Z" />
            <path class="cls-1" d="M1523,204.65c18.32,0,34.16-1.09,49.8.24,26.07,2.21,47,24.49,49.83,51.28a134.57,134.57,0,0,1,.42,14.21q0,451.14-.39,902.28c0,13.6-3.27,28.72-9.81,40.45-11.43,20.51-31.53,29.52-55,25.63-42-7-83.62-16.38-125.7-22.73-162.57-24.49-324.88-27.84-491.36,10.17,10.75-9.7,17.79-16.7,25.49-22.88,78.82-63.35,170.41-97.32,268.9-113,45.07-7.21,91-10,136.65-12.37,92-4.71,151-65.09,151.12-157.67q.3-323.15.06-646.3Z" />
        </SvgIcon>
    );
}

export default LibraryIcon;