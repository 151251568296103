import { useState } from 'react';
import { axios } from 'utils/api';
import { useSnackbar } from 'notistack';

export default function useHash(ltiId) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const openAlgetec = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post('/algetec/ltihash', { algetec_labs_id: ltiId });
      window.open(
        `${axios.defaults.baseURL.slice(0, -3)}lti-tasting-lab/${data.data.hash}`
      );
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Algo deu errado', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return { loading, openAlgetec };
}
