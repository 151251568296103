import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SplashScreen } from 'components';
import authService from 'utils/api';
import { logout, login, refreshAccount } from 'store/accountSlice';
import { fetchAlerts } from 'store/alertsSlice';

function Auth(props) {
  const { children } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.account);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      });
      await authService.handleAuthentication().catch((err) => {
        console.log('Erro de autenticação handleAuthentication err');
        return err;
      });
      if (authService.isAuthenticated()) {
        await dispatch(login());
        await dispatch(refreshAccount());
        await dispatch(fetchAlerts())
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch, history]);

  if (isLoading || loading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
