import React from 'react';
import { Box } from '@material-ui/core';
import slugify from 'slugify';
import publicIp from 'public-ip';

export const generateFileName = (str, url) => {
  const date = new Date().toLocaleDateString({ dateStyle: 'short' }).split('/').join('');
  const ext = url.split('.').pop();
  return `${date}_${slugify(str).toLowerCase()}.${ext}`;
};

export const capitalize = (str) => {
  const newStr = str.toLowerCase();
  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

export const removeNullProps = (obj) => {
  for (let prop in obj) {
    if (!obj[prop]) {
      delete obj[prop];
    }
  }
  return obj;
};

export const capitalizeSentence = (str) =>
  str
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');

export const insertEmbed = (str) => str.slice(0, 25) + 'embed/' + str.slice(25);

export const wait = (time) => new Promise((res) => setTimeout(res, time));

export const shortenString = (str, len = 45) => {
  if (str.length > len) {
    return str.slice(0, len - 1) + '...';
  }
  return str;
};

export const parseErrors = (errors) => (
  <Box>
    {Object.entries(errors).map((error) => (
      <li key={error[1]}>{error[1]}</li>
    ))}
  </Box>
);

export const dateFormat = (date, size) => {
  const options = {};
  switch (size) {
    case 'year':
      options.year = 'numeric';
      break;

    case 'month-year':
      options.year = 'numeric';
      options.month = 'long';
      break;

    case 'day-month-year':
      options.year = 'numeric';
      options.month = 'long';
      options.day = 'numeric';
      break;

    case 'day-month':
      options.day = 'numeric';
      options.month = 'long';
      break;
    
    case 'day-month-year-time':
      options.year = 'numeric';
      options.month = 'numeric';
      options.day = 'numeric';
      options.hour = '2-digit'
      options.minute = '2-digit'
      break;

    default:
      break;
  }
  return date.toLocaleString('pt-br', options);
};

export const getClientIp = async () => await publicIp.v4({
  fallbackUrls: [ "https://ifconfig.co/ip" ]
});

export const downloadFile = ( fileUrl, name ) => {
  fetch(fileUrl)
  .then(response => {
    response.blob().then(blob => {
      const getUrlExtension = ( url ) => (
        url.split(/[#?]/)[0].split('.').pop().trim()
      )
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = `${name}.${getUrlExtension(fileUrl)}`;
      a.click();
    })
  })
}
