import { makeStyles } from '@material-ui/core';

export const useHomeStyles = makeStyles((theme) => ({
  blinking: {
    animation: '$blink 1s linear 10',
  },

  "@keyframes blink": {
    from: { opacity: 1 },
    to: { opacity: .1 }
  }
}))

export const useProfileStyles = makeStyles((theme) => ({
  avatar: {
    height: 96,
    width: 96,
    border: '2px solid white',
  },
  chip: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    fontWeight: 500,
    borderRadius: theme.shape.borderRadius * 2.5,
  },
  progress: {
    height: 25,
    borderRadius: 8,
  },
}));

export const useCardStyles = makeStyles((theme) => ({
  liveButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  liveButtonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const useAlertStyles = makeStyles({
  button: {
    backgroundColor: '#fff',
    marginTop: '-36px',
    fontSize: '12px',
  },
  icon: {
    color: '#002532',
    '&:hover': {
      color: '#fff',
    },
  },
});

export const useDropStyles = makeStyles((theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 50,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));
