import { useState } from 'react';
import api, { axios } from 'utils/api';
import { useSnackbar } from 'notistack';

export default function useLibrary() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const goToLibrary = async () => {
    setLoading(true);
    try {
      const token = api.getAccessToken();
      window.open(`${axios.defaults.baseURL}/my-libraries?token=${token}`);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Algo deu errado! Entre em contato com o suporte', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return { loading, goToLibrary };
}
