import _ from 'lodash';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import typography from './typography';
import { softShadows } from './shadows';
import { THEMES } from '../constants';

const baseConfig = {
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: colors.yellow[500],
      },
    },
  },
};

const themeConfigs = [
  {
    name: THEMES.ROCKET,
    overrides: {
      MuiTableCell: {
        head: {
          color: colors.common.white,
          fontWeight: '600',
        },
        body: {
          color: colors.common.white,
        },
      },
      MuiTablePagination: {
        root: {
          color: colors.common.white,
        },
        action: {
          color: colors.common.white,
        },
      },
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
      MuiSelect: {
        iconOutlined: {
          color: colors.common.white,
        },
      },
      MuiOutlinedInput: {
        root: {
          color: colors.common.white,
        },
        notchedOutline: {
          borderColor: colors.common.white,
        },
      },
    },
    palette: {
      type: 'light',
      action: {
        active: '#003045',
      },
      background: {
        default: colors.common.white,
        dark: colors.grey[100],
        paper: colors.common.white,
      },
      primary: {
        main: colors.lightBlue[600],
      },
      secondary: {
        main: '#00CC96',
      },
      text: {
        primary: '#606060',
        secondary: '#003045',
      },
      error: {
        main: '#FD6969',
      },
      warning: {
        main: '#FD9F27',
        secondary: '#ffeb3b',
      },
      info: {
        main: colors.common.white,
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiTableCell: {
        head: {
          color: colors.common.black,
          fontWeight: '600',
        },
        body: {
          color: colors.common.black,
        },
      },
      MuiTablePagination: {
        root: {
          color: colors.common.black,
        },
        action: {
          color: colors.common.black,
        },
      },
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.common.black,
          },
        },
      },
      MuiSelect: {
        iconOutlined: {
          color: colors.common.black,
        },
      },
      MuiOutlinedInput: {
        root: {
          color: colors.common.black,
        },
        notchedOutline: {
          borderColor: colors.common.black,
        },
      },
    },
    palette: {
      type: 'light',
      action: {
        active: '#003045',
      },
      background: {
        default: colors.common.white,
        dark: colors.grey[100],
        paper: colors.common.white,
      },
      primary: {
        main: colors.lightBlue[600],
      },
      secondary: {
        main: '#00CC96',
      },
      text: {
        primary: '#606060',
        secondary: '#003045',
      },
      error: {
        main: '#FD6969',
      },
      warning: {
        main: '#FD9F27',
        secondary: '#ffeb3b',
      },
      info: {
        main: colors.common.white,
      },
    },
    shadows: softShadows,
  },
];

export function createTheme(settings) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`O tema ${settings.theme} não é válido`));
    [themeConfig] = themeConfigs;
  }
  let theme = createMuiTheme(_.merge({}, baseConfig, themeConfig), ptBR);

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
