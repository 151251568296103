import React from 'react';
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumbs, Link, Typography, Box, IconButton } from '@material-ui/core';
import { NavigateNext, ArrowBack } from '@material-ui/icons';

import { capitalize } from 'utils/helper';

function Header(props) {
  const { id, title } = props;
  const { pathname } = useLocation();
  const history = useHistory();

  const generateCrumbs = (path) => {
    const crumbs = [];
    const rawCrumbs = path.split('/');
    while (rawCrumbs.length > 0) {
      let name = rawCrumbs[rawCrumbs.length - 1];
      if (name === id) {
        name = title;
      }
      const obj = {
        path: rawCrumbs.join('/') || '/',
        name: capitalize(name) || 'Home',
      };
      crumbs.unshift(obj);
      rawCrumbs.pop();
    }
    return crumbs;
  };

  return (
    <Box color='white'>
      <Box display='flex' alignItems='center' mb={2}>
        <Box mr={2}>
          <IconButton
            size='small'
            style={{
              border: '1px solid white',
              borderRadius: 5,
            }}
            color='inherit'
            onClick={() => history.goBack()}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Breadcrumbs
        style={{ color: '#fff' }}
        separator={<NavigateNext fontSize='small' />}
        aria-label='breadcrumb'
      >
        {generateCrumbs(pathname).map((crumb) => (
          <Link
            key={crumb.path}
            variant='body1'
            color='inherit'
            to={crumb.path}
            component={RouterLink}
          >
            {crumb.name}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
}

Header.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Header;
