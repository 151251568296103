import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { LoadingScreen } from 'components';
import { AuthRoute, GuestRoute } from 'components/Auth';

function Routes() {
  const Error404 = lazy(() => import('components/Error404'));
  const Home = lazy(() => import('pages/Home'));
  const Login = lazy(() => import('pages/Login'));
  const PasswordForgot = lazy(() => import('pages/PasswordForgot'));
  const PasswordRecovery = lazy(() => import('pages/PasswordRecovery'));
  const Lesson = lazy(() => import('pages/Lesson'));
  const Subject = lazy(() => import('pages/Subject'));
  const Exercises = lazy(() => import('pages/Exercises'));
  const ExerciseDetails = lazy(() => import('pages/Exercises/Details'));
  const Lab = lazy(() => import('pages/Lab'));
  const LabList = lazy(() => import('pages/Lab/List'));
  const Profile = lazy(() => import('pages/Profile'));
  const Alerts = lazy(() => import('pages/Alerts'));
  const AcademicServices = lazy(() => import('pages/AcademicServices'));

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route exact path='/404' component={Error404} />
        <Route exact path='/recuperar-senha/:token' component={PasswordRecovery} />
        <GuestRoute exact path='/login' component={Login} />
        <GuestRoute exact path='/esqueci-a-senha' component={PasswordForgot} />
        <AuthRoute
          path='/'
          render={() => (
            <Suspense fallback={<LoadingScreen />}>
              <Switch>
                <Redirect exact from='/' to='/home' />
                <Route exact path='/home' component={Home} />
                <Route exact path='/atividades' component={Exercises} />
                <Route exact path='/perfil' component={Profile} />
                <Route exact path='/comunicados' component={Alerts} />
                <Route exact path='/servicos academicos' component={AcademicServices} />
                <Route exact path='/atividades/:id' component={ExerciseDetails} />
                <Route exact path='/laboratorios' component={Lab} />
                <Route exact path='/laboratorios/:id' component={LabList} />
                <Route exact path='/:id' component={Subject} />
                <Route exact path='/:subjectId/:id' component={Lesson} />
                <Redirect to='/404' />
              </Switch>
            </Suspense>
          )}
        />
      </Switch>
    </Suspense>
  );
}

export default Routes;
