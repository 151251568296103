import React from 'react';
import { Badge } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';

export default function AlertBadge(props) {
    const { value } = props;

    return (
        <Badge badgeContent={value} color="primary">
            <Notifications color="secondary" />
        </Badge>
    );
}