import React from 'react';

function LogoTrivento(props) {
  const { color = 'white' } = props;
  
  return (
    <svg
      version="1.1"
      viewBox="0 0 1810 426.5"
      enableBackground="new 0 0 1810 426.5"
      height="2rem"
      fill={color}
    >
      <g>
        <path
          d="M1807.6,1.1c-1-0.7-2.1-1.1-3.3-1.1h-324.1c-3.1,0-5.7,2.5-5.7,5.7c0,1.2,0.4,2.3,1.1,3.3l25.8,36.5h-113.8
		c-3.1,0-5.7,2.5-5.7,5.7c0,1.2,0.4,2.3,1.1,3.3l25.8,36.5H1295c-3.1,0-5.7,2.5-5.7,5.7c0,1.2,0.4,2.3,1.1,3.3l162,229.3
		c1.8,2.6,5.4,3.2,7.9,1.4c0.5-0.4,1-0.8,1.4-1.4l57.7-81.7l25.6,36.2c1.8,2.6,5.4,3.2,7.9,1.4c0.5-0.4,1-0.8,1.4-1.4l57.7-81.7
		l25.6,36.2c1.8,2.6,5.4,3.2,7.9,1.4c0.5-0.4,1-0.8,1.4-1.4L1808.9,9C1810.7,6.4,1810.1,2.9,1807.6,1.1z M1459.3,293.1
		c-0.9,1.3-2.7,1.6-4,0.7c-0.3-0.2-0.5-0.4-0.7-0.7v0l-123.6-174.9c-0.9-1.3-0.6-3.1,0.7-4c0.5-0.3,1-0.5,1.6-0.5h247.2
		c1.6,0,2.8,1.3,2.8,2.9c0,0.6-0.2,1.2-0.5,1.6L1459.3,293.1z M1551.9,247.6c-0.9,1.3-2.7,1.6-4,0.7c-0.3-0.2-0.5-0.4-0.7-0.7v0
		l-14-19.8l90.4-127.9c1.8-2.6,1.2-6.1-1.3-7.9c-1-0.7-2.1-1.1-3.3-1.1h-182.4l-12.9-18.2c-0.9-1.3-0.6-3.1,0.7-4
		c0.5-0.3,1.1-0.5,1.6-0.5h247.2c1.6,0,2.8,1.3,2.8,2.8c0,0.6-0.2,1.2-0.5,1.7L1551.9,247.6z M1768.1,27.2l-123.6,174.9
		c-0.9,1.3-2.7,1.6-4,0.7c-0.3-0.2-0.5-0.4-0.7-0.7l-14-19.8l90.4-127.9c1.8-2.6,1.2-6.1-1.3-7.9c-1-0.7-2.1-1.1-3.3-1.1h-182.4
		l-12.9-18.2c-0.9-1.3-0.6-3.1,0.7-4c0.5-0.3,1.1-0.5,1.6-0.5h247.2c1.6,0,2.8,1.3,2.8,2.9C1768.6,26.2,1768.5,26.8,1768.1,27.2z"
        />
        <path d="M199,32.5H0v29.8C0,70.4,6.5,77,14.6,77c0,0,0,0,0,0H75v206c0,8.1,6.5,14.6,14.6,14.6h33.9V77H199V32.5z" />
        <path
          d="M299.6,108.9c-6.2-2.1-12.7-3.1-19.2-3.1c-9.5,0-18.7,3.4-27.5,10.2c-3.8,3-7.2,6.3-10.3,10V110h-29
		c-8.1,0-14.6,6.5-14.7,14.6c0,0,0,0,0,0.1V283c0,8.1,6.5,14.6,14.6,14.6h29V163.4c1.8-4.1,4.6-7.7,8-10.6
		c4.6-3.8,10.6-5.7,17.9-5.7c5.4,0,9.9,0.8,13.5,2.5c4.1,2,7.9,4.4,11.4,7.3l21.2-37.1C310.8,114.7,305.6,111,299.6,108.9z"
        />
        <path d="M330.1,124.6V283c0,8.1,6.6,14.6,14.6,14.6h28.6V110h-28.6C336.6,110,330.1,116.5,330.1,124.6z" />
        <path
          d="M532.7,110l-48.9,115l-48.9-115h-48.1l83.9,177c3.5,7.3,12.2,10.4,19.5,6.9c3-1.4,5.5-3.9,6.9-6.9l84.3-177
		H532.7z"
        />
        <path
          d="M887.7,105.9c-13.1,0-24.3,2.7-33.7,8.2S837,127.8,831,139v-26.5c0-5.1-4.2-9.3-9.3-9.3h-25.5
		c-5.1,0-9.3,4.2-9.3,9.3v175.8c0,5.1,4.2,9.3,9.3,9.3h25.5c5.1,0,9.3-4.2,9.3-9.3V183.4c-0.2-7.7,1.7-15.4,5.3-22.2
		c3.4-6.2,8.5-11.3,14.7-14.7c6.7-3.5,14.1-5.3,21.6-5.1c12,0,20.8,3.5,26.5,10.6s8.6,17.5,8.6,31.4v104.9c0,5.1,4.2,9.3,9.3,9.3
		h26.7c5.1,0,9.3-4.2,9.3-9.3c0,0,0,0,0,0V178.5c0-22.6-5.6-40.3-16.7-53.2C925.1,112.4,908.9,105.9,887.7,105.9z"
        />
        <path
          d="M1022.7,65.1h-23.2c-11.3,0-20.5,9.2-20.5,20.5V283c0,8.1,6.5,14.6,14.6,14.6h29V148.7h31.4V110h-31.4V65.1
		z"
        />
        <path
          d="M1217.6,118.4c-15.1-8.3-32.3-12.4-51.6-12.4s-36.6,4.1-51.8,12.4c-14.9,8-27.4,19.9-36.1,34.5
		c-8.8,14.7-13.3,31.7-13.2,51c0,19,4.4,36,13.2,50.8c8.6,14.6,21.1,26.6,36.1,34.7c15.2,8.3,32.5,12.4,51.8,12.4
		s36.5-4.2,51.6-12.4c14.9-8.1,27.4-20.1,36.1-34.7c9-14.8,13.5-31.7,13.4-50.8c0-19.3-4.5-36.3-13.4-51
		C1245,138.3,1232.5,126.5,1217.6,118.4z M1214.6,234.6c-4.7,8.6-11.7,15.8-20.2,20.8c-26.1,15.7-60.1,7.2-75.8-18.9
		c-0.4-0.6-0.7-1.3-1.1-1.9c-4.9-8.8-7.3-19.1-7.3-30.8s2.4-22,7.3-31c4.6-8.7,11.5-15.9,20-20.8c17.7-9.8,39.2-9.8,56.9,0
		c8.6,4.9,15.6,12.1,20.2,20.8c4.9,9,7.3,19.3,7.3,31C1221.9,215.5,1219.5,225.8,1214.6,234.6z"
        />
        <path
          d="M668.9,104.3c-28.7,0-52.3,9.8-70.4,29.1c-16.9,18.5-25.5,42.1-25.5,70.3c0,28,9,51.7,26.6,70.4
		c18.2,19.3,42.1,29,71,29c22.7,0,41.7-5.5,56.3-16.4c13.5-10.1,24.1-23.7,30.5-39.4h-37.7c-3.9,0-7.7,1.6-10.4,4.4
		c-9.4,9.8-22,14.7-38.3,14.7s-29.5-4.9-40-15c-8.7-8.3-14.7-19.1-17-30.9h150.7v-16.9c0-28.2-8.6-51.9-25.5-70.4
		C721.2,114.1,697.5,104.3,668.9,104.3z M614.7,183.3c2.5-10.8,8.2-20.7,16.3-28.3c10.3-9.4,22.7-14,37.9-14s27.7,4.6,37.9,14
		c8.1,7.6,13.8,17.5,16.3,28.3L614.7,183.3z"
        />
      </g>
    </svg>
  );
}

export default LogoTrivento;